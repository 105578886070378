@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html, body {
    height: 100%;
}

#root, .App {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: "Montserrat";
}

textarea {
    border: none;
    overflow: auto;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    resize: none; /*remove the resize handle on the bottom right*/
}

.message {
    margin-bottom: 50px;
    margin-left: 50px;
    margin-right: 25px;
    position: relative;
}

.message img {
    position: absolute;
    left: -40px;
}